


































































































import Vue from "vue";

export default Vue.extend({
  name: "LicensePage",
  data: () => ({
    items: [
      {
        header: "Open Source Code",
        text:
          "Here we list all the open source software we use to build Worldtrix. Without the work from all this open source developers Worldtrix would be impossible. Special thanks to all of the developers who are providing such awesome software! If you are one of the core members of one of the used software below you can contact us to get a free full version when the game will be released.",
      },
      { divider: true, inset: true },
      {
        title: "vuejs",
        webLink: "https://vuejs.org/",
        codeLink: "https://github.com/vuejs/vue",
        licenseText: "MIT",
        licenseLink: "/licenses/LICENSE_vue.md",
        image: "/licenses/logos/vue-logo.svg",
        text:
          "Vue JS builds the fondation of the Worldtrix frontend. We use vuejs because it is easy to learn and use. Vue allows us to build a browser based game prototype without the need to throw everything a when we start with the server implementation. Great thanks to all vuejs developers whose make Worldtrix possible!",
      },
      { divider: true, inset: true },
      {
        title: "vuetify",
        webLink: "https://vuetifyjs.com/",
        codeLink: "https://github.com/vuetifyjs/vuetify",
        licenseText: "MIT",
        licenseLink: "/licenses/LICENSE_vuetify.md",
        image: "/licenses/logos/vuetify-logo.svg",
        text:
          "Vuetfiy provides us with awesome frontend elements to develop powerful features for a handling the gameplay in a very easy way. Without vuetify this game would no be possible with our current resources. Special Thanks to John Leider and all vuetify developers!",
      },
      { divider: true, inset: true },
      {
        title: "CesiumJS",
        webLink: "https://cesiumjs.org/",
        codeLink: "https://github.com/AnalyticalGraphicsInc/cesium",
        licenseText: "Apache 2.0",
        licenseLink: "/licenses/LICENSE_cesiumJS.md",
        image: "/licenses/logos/cesiumjs_credit.png",
        text:
          "Cesium provides the webgl implementation for worldtrix and all the fronend calculation regarding working with coordinates. Cesium allows the fast prototyping approach and thankfully to the monthly updates Cesium is always improving. Cesium allows use to show you some graphics instead of only number crunching. Huge thanks to the whole cesium dev-team!",
      },
      { divider: true, inset: true },
      {
        title: "vuex",
        webLink: "https://vuex.vuejs.org/",
        codeLink: "https://github.com/vuejs/vuex",
        licenseText: "MIT",
        licenseLink: "/licenses/LICENSE_vuex.md",
        image: "",
        text:
          "No vuex === death. What else is there to say except vuex is providing a solution that is propably the best possible SPA data mangement that is possible, at least in JavaScript :). Many thanks to all the devs!",
      },
      { divider: true, inset: true },
      {
        title: "vue-router",
        webLink: "https://router.vuejs.org/",
        codeLink: "https://github.com/vuejs/vue-router",
        licenseText: "MIT",
        licenseLink: "/licenses/LICENSE_vue-router.md",
        image: "",
        text:
          "No vue-router => 404. To tell the truth you wouldn't even see 404. Thanks devs for this awesome solution!",
      },
      { divider: true, inset: true },
      {
        title: "vue-shortkey",
        webLink: "",
        codeLink: "https://github.com/iFgR/vue-shortkey",
        licenseText: "MIT",
        licenseLink: "/licenses/LICENSE_vue-shortkey.md",
        image: "",
        text:
          "No one likes to do all the work by click or implementing keystrokes by hand. Thanks to the vue-shortkey developers for giving us (literally) a 2nd hand!",
      },
      { divider: true, inset: true },
      { header: "Content" },
      { divider: true, inset: true },
      {
        title: "Natural Earth",
        webLink: "https://www.naturalearthdata.com/about/terms-of-use/",
        codeLink: "",
        licenseText: "Public Domain",
        licenseLink: "https://creativecommons.org/publicdomain/",
        image: "",
        text:
          "We use the natural Earth data as basic earth texture. We think it looks pretty cool in high resolution :).",
      },
      { divider: true, inset: true },
      {
        title: "Google Font Roboto",
        webLink: "https://fonts.google.com/specimen/Roboto",
        codeLink: "",
        licenseText: "Apache License Version 2.0",
        licenseLink: "http://www.apache.org/licenses/LICENSE-2.0",
        image: "",
        text:
          "A nice readable font directly shipped from our server without a CDN.",
      },
      { divider: true, inset: true },
      {
        title: "Material Design Icons",
        webLink: "https://materialdesignicons.com/",
        licenseText: "SIL Open Font License Version 1.1",
        licenseLink: "/licenses/license_mdi-font.md",
        image: "",
        text:
          "Icons for having a nicer look and help you to remember and connect different things.",
      },
      { divider: true, inset: true },
      {
        title: "Material Desing Icons Iconfont",
        webLink: "https://jossef.github.io/material-design-icons-iconfont/",
        codeLink: "",
        licenseText: "Apache License Version 2.0",
        licenseLink: "/licenses/LICENSE_material-design-icons-iconfont.md",
        image: "",
        text:
          "Icons for having a nicer look and help you to remember and connect different things.",
      },
      { divider: true, inset: true },
      {
        header: "Software & Knowledge",
        text:
          "We also use a lot of awesome free software, to build and run Worldtrix. We will also list them here  in future to also thanks all the involved persons in this projects.",
      },
      { divider: true, inset: true },
    ],
  }),
  methods: {
    empty: function() {},
  },
});
