











import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Account",
  components: {},
  data: () => ({}),
  computed: {
    ...mapGetters(["sUiAccessTier"]),
  },
});
